export default {
  metaInfo() {
    return {
      title: this.vueMeta.title,
      link: this.vueMeta.link,
      meta: this.vueMeta.meta,
      script: this.vueMeta.script,
    };
  },
  data: () => ({
    pageData: null,
    vueMeta: {
      title: "",
      link: [],
      meta: [],
      script: [],
    },
  }),
  computed: {
    isIndexPage() {
      // TODO
      return false;
    },
    breadcrumb() {
      // TODO
      return [];
    },
    siteName() {
      return this.$store.getters["base/siteName"];
    },
    pageTitle() {
      return this.seoTitle
    },
    defaultSeoTitle() {
      return this.$store.getters['base/seoTitle']
    },
    defaultSeoDescription() {
      return this.$store.getters['base/seoDescription']
    },
    defaultSeoKeywords() {
      return this.$store.getters['base/seoKeywords']
    },
    seoTitle() {
      // index page TODO
      if (!this.pageData) return this.defaultSeoTitle;
      return this.pageData.seo_title || this.defaultSeoTitle;
    },
    seoDescription() {
      // index page TODO
      if (!this.pageData) return this.defaultSeoDescription;
      return this.pageData.seo_description || this.defaultSeoDescription;
    },
    seoKeywords() {
      // index page TODO
      if (!this.pageData) return this.defaultSeoKeywords;
      return this.pageData.seo_keywords || this.defaultSeoKeywords;
    },
    seoPhoto() {
      if (!this.pageData) return null;
      return this.pageData.seo_photo;
    },
    hasSecretLink() {
      // detail page TODO
      return false
    },
    hasPreviewLink() {
      // detail page TODO
      return false;
    },
  },
  methods: {
    async setupMeta() {
      const metaConfig = {
        title: this.pageTitle,
        description: this.seoDescription,
        keywords: this.seoKeywords,
        photo: this.seoPhoto,
      }
      this.vueMeta.title = this.pageTitle;
      this.vueMeta.meta = this.$seo.getMetaArray(metaConfig);

      // const url = `${window.location.origin}${this.$route.path}`;
      // this.vueMeta.link = [{ rel: "canonical", href: url }];
      if(this.hasSecretLink || this.hasPreviewLink) return

      await this.$nextTick();
      this.$ssr();
    },
    setBreadcrumb() {
      this.$store.commit("base/setBreadcrumb", this.breadcrumb);
    },
  },
};
