import PerfectScrollbar from "perfect-scrollbar";
export default {
  data: () => ({
    scrollbarContainers: [],
    $target: null, // TODO,
    hasY: true, // TODO
  }),
  computed: {
    windowWidth() {
      return this.$store.getters["base/windowWidth"];
    },
  },
  watch: {
    windowWidth() {
      this.scrollbarContainers.forEach((container) => {
        container.update();
        this.isHideY();
      });
    },
  },
  methods: {
    initScrollbar() {
      if (!this.$target) {
        console.warn("未指定scrollbarContainer");
        return;
      }
      const _this = this;
      this.scrollbarContainers = [];
      this.$target.each(function() {
        const target = $(this)[0];
        const ps = new PerfectScrollbar(target);
        _this.scrollbarContainers.push(ps);
      });

      this.isHideY();
    },
    isHideY() {
      if (!this.hasY) {
        $(this.$el)
          .find(".ps__rail-y")
          .hide();
      }
    },
  },
};
